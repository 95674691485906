import React from 'react';

export default () => (
  <svg height="57.091" width="233.555" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M70.294 17.789c0-2.44.155-3.064.83-4a4.433 4.433 0 013.948-1.765h7.475a18.879 18.879 0 0111.114 3.117c4.05 2.96 6.387 8.257 6.387 14.385-.001 10.389-6.595 17.289-16.301 17.134l-7.737-.1c-4.153.156-5.92-1.661-5.711-5.763zm11.821 23.19c6.789 0 10.5-4.121 10.5-11.665 0-4.062-1.161-7.139-3.54-9.227-1.916-1.683-4.237-2.38-8.008-2.38a3.365 3.365 0 00-3.366 3.366v19.906zM108.69 17.926a3.873 3.873 0 01-3.826-3.875 3.829 3.829 0 013.826-3.826 3.871 3.871 0 013.873 3.826 3.915 3.915 0 01-3.873 3.875zm4.659 28.6c-5.1 0-7.9-2.894-7.9-8.241V22.241a1.865 1.865 0 011.865-1.863c2.942 0 4.708 1.57 4.708 4.9v12.366c0 2.647 1.128 3.482 4.022 3.482a1.227 1.227 0 011.226 1.226v.2c.003 2.841-1.125 3.97-3.921 3.97zM129.818 46.864c-4.856 0-8.485-2.5-8.485-5.2a1.964 1.964 0 012.669-1.836 17.327 17.327 0 005.523 1.346c3.236 0 4.806-.933 4.806-2.894 0-1.521-.931-2.256-5.494-2.894-5.59-.784-8.288-2.991-8.288-7.013 0-4.954 4.463-9.173 11.232-9.173 5.983 0 8.436 3.089 8.436 5.64a1.6 1.6 0 01-2.21 1.483 16.39 16.39 0 00-5.589-1.434c-4.021 0-5.3 1.226-5.3 2.944 0 1.324.784 1.865 5.3 2.6 5.494.882 8.438 3.287 8.438 7.5-.001 5.597-3.925 8.931-11.038 8.931z"
      fill="#1552b4"
    />
    <path
      d="M183.952 46.212c-3.575 0-5-1.672-5-4.81v-17.21c0-4.361-1.963-6.22-4.854-6.22-2.645 0-4.463 1.859-4.463 5.492v21.148a1.628 1.628 0 01-1.612 1.628h-.148a4.36 4.36 0 01-4.812-4.81V24.221c0-4.361-2-6.22-4.9-6.22-2.558 0-4.461 2-4.461 5.638v21a1.629 1.629 0 01-1.628 1.628h-.139a4.522 4.522 0 01-4.76-4.269 4.429 4.429 0 010-.538V24.119c0-7.266 3.779-12.02 11.772-12.02a9.724 9.724 0 018.036 4.128 9.013 9.013 0 017.6-4.128c6.076 0 10.988 3.677 10.988 11.192v21.292a1.629 1.629 0 01-1.628 1.628zM203.817 19.136c7.935 0 12.5 6.38 12.5 13.386 0 7.948-4.563 14.27-13.078 14.27-7.936 0-12.5-6.322-12.5-13.326-.015-7.949 4.548-14.33 13.078-14.33zm-.566 5.931c-3.779 0-5.683 3.138-5.683 7.5 0 5.245 1.9 8.385 6.278 8.385 3.822 0 5.683-3.14 5.683-7.441-.028-5.35-1.918-8.444-6.277-8.444zM225.051 17.872a3.878 3.878 0 01-3.822-3.88 3.822 3.822 0 013.822-3.822 3.88 3.88 0 013.879 3.822 3.923 3.923 0 01-3.879 3.88zm4.665 28.587c-5.1 0-7.906-2.907-7.906-8.241V22.16a1.876 1.876 0 011.788-1.847c2.905 0 4.708 1.57 4.708 4.912v12.354c0 2.645 1.119 3.473 4.011 3.473a1.235 1.235 0 011.235 1.235v.218c.058 2.82-1.075 3.953-3.866 3.953z"
      fill="#17baae"
    />
    <path
      d="M30.971 0C14.576 0 1.283 12.78 1.283 28.546a27.508 27.508 0 002.252 10.92 5.571 5.571 0 01-.453 5.207l-2.523 3.92a3.483 3.483 0 002.925 5.367h2.159a71.772 71.772 0 0117.672 2.169 30.806 30.806 0 007.655.959c16.4 0 29.688-12.78 29.688-28.546S47.367 0 30.971 0z"
      fill="#1552b4"
    />
    <path
      d="M44.389 12.077c-3.127-2.299-7.61-3.538-12.627-3.538h-7.413a6.494 6.494 0 00-5.932 3.853 6.545 6.545 0 00-.615 2.78l.005 2.441v20.019c-.257 2.62-1.73 3.482-5.021 3.482a1.544 1.544 0 00-1.545 1.545v.247c0 2.454.668 3.891 2.216 4.555a2.336 2.336 0 001.313.384h.262a10.325 10.325 0 001.152.06c.458 0 .9-.02 1.328-.056l13.233.022 2.338.033a18.374 18.374 0 0010.726-2.984c4.9-3.289 7.835-9.123 7.835-16.508 0-6.956-2.656-12.973-7.255-16.335zm-13.047 27.98h-4.487V19.813a3.424 3.424 0 013.424-3.424c3.835 0 6.2.708 8.145 2.42 2.42 2.125 3.6 5.254 3.6 9.385 0 7.672-3.778 11.863-10.682 11.863z"
      fill="#fff"
    />
  </svg>
);
