import React from 'react';

export default () => (
  <svg
    height="36.948"
    viewBox="0 0 32.145 36.948"
    width="32.145"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m49.657 36.845a16.16 16.16 0 0 0 -1.668-32.213v-4.348a.312.312 0 0 0 -.506-.219l-8.952 6.574a.276.276 0 0 0 0 .445l8.951 6.574a.314.314 0 0 0 .506-.219v-4.339a11.7 11.7 0 1 1 -11.744 13.571 2.226 2.226 0 0 0 -4.4.672 16.183 16.183 0 0 0 17.813 13.502z"
      fill="#fff"
      transform="translate(-31.817)"
    />
  </svg>
);
