import React from 'react';

export default () => (
  <svg
    height="63.956"
    viewBox="0 0 82.887 63.956"
    width="82.887"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(-221.295 -233.536)">
      <path
        d="m230.524 236.975c-5.485 4.238-8.7 15.922-7.43 26.112s0 21.441 11.039 23.565 21.441-1.274 23.351.849 4.459 8.492 6.794 8.492 4.246-6.794 6.581-8.067 21.654.637 25.475-5.1 11.248-37.331 1.486-43.944c-6.581-4.458-22.5-2.335-35.028-2.972-9.967-.504-27.598-2.544-32.268 1.065z"
        fill="#fff"
        stroke="#0f4db4"
        strokeWidth="3"
      />
      <path
        d="m261.071 263.938a2.7 2.7 0 1 1 -2.675-3.08 2.9 2.9 0 0 1 2.675 3.08z"
        fill="#004597"
        transform="translate(-12.974 -10.242)"
      />
      <path
        d="m315.822 260.521a2.7 2.7 0 1 1 -2.676-3.079 2.9 2.9 0 0 1 2.676 3.079z"
        fill="#004597"
        transform="translate(-34.56 -8.895)"
      />
      <path
        d="m284.7 282.7a13.149 13.149 0 0 1 11.733 2.253c-1.684 3.428-5.294 6.59-11.43 7.033a14.43 14.43 0 0 1 -8.523-1.835l.042-.085a12.692 12.692 0 0 1 8.178-7.366z"
        fill="#de0041"
        transform="translate(-21.159 -18.658)"
      />
      <path
        d="m292.012 271.176c1.75 1.393 2.084 5.252.321 8.819a13.149 13.149 0 0 0 -11.733-2.253 12.692 12.692 0 0 0 -8.177 7.366l-.042.085c-5.76-3.392-7.4-10.309-5.609-11.691 5.348-4.143 21.806-5.052 25.24-2.326z"
        fill="#004597"
        transform="translate(-17.058 -13.697)"
      />
    </g>
  </svg>
);
