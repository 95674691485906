import React from 'react';

export default () => (
  <svg width="52.844" height="52.813" viewBox="0 0 52.844 52.813">
    <path
      fill="#707070"
      d="M38.756 28.633a16.538 16.538 0 1 0-23.2-.741A25.12 25.12 0 0 0 0 50.609a2.2 2.2 0 1 0 4.4 0 20.76 20.76 0 0 1 15.373-19.494 16.407 16.407 0 0 0 14.578.458 20.893 20.893 0 0 1 14.081 19.034 2.2 2.2 0 0 0 2.2 2.2 2.2 2.2 0 0 0 2.2-2.2 25.311 25.311 0 0 0-14.076-21.974zm-17.557-1.774a12.006 12.006 0 0 1-5.8-10.328 12.137 12.137 0 1 1 5.8 10.328z"
    />
  </svg>
);
