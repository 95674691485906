import React from 'react';

export default () => (
  <svg viewBox="0 0 13 13" width="13" height="13">
    <path
      d="M1.007 0A1.006 1.006 0 0 0 .3 1.718L5.191 6.6.319 11.462a1.006 1.006 0 0 0 .712 1.717 
      1 1 0 0 0 .712-.294l4.874-4.862 4.9 4.885a1.007 1.007 0 1 0 1.424-1.423L8.042 6.6l4.872-4.86A1.007 
      1.007 0 0 0 11.491.318L6.617 5.18 1.719.294A1 1 0 0 0 1.007 0z"
    />
  </svg>
);
