import React from 'react';

export default () => (
  <svg width="31.414" height="32.729">
    <path
      fill="#404348"
      d="M7.195 23.234a2.947 2.947 0 1 0 2.077 5.033 2.94 2.94 0 0 0 0-4.174 2.859 2.859 0 0 0-2.077-.86zm0
       0M6.546 17.018a3.15 3.15 0 0 0-.963-2.313 3.152 3.152 0 0 0-2.31-.96 3.159 3.159 0 0 0-2.313.96 3.261
        3.261 0 0 0 0 4.622 3.154 3.154 0 0 0 2.313.96 3.147 3.147 0 0 0 2.31-.96 3.138 3.138 0 0 0
         .963-2.31zm0 0M25.525 9.49a1.639 1.639 0 1 0-1.155-.48 1.576 1.576 0 0 0 1.155.48zm0 0M7.198
          4.255a3.595 3.595 0 0 0-3.6 3.6 3.595 3.595 0 0 0 3.6 3.6 3.595 3.595 0 0 0 3.6-3.6 3.595 3.595 0 0
           0-3.6-3.6zm0 0M30.843 15.627a1.977 1.977 0 1 0 .571 1.39 1.882 1.882 0 0 0-.571-1.39zm0 0M25.525
            23.89a2.3 2.3 0 1 0 1.616.675 2.195 2.195 0 0 0-1.616-.675zm0 0M16.365 27.489a2.625 2.625 0 1 0
             1.851.769 2.521 2.521 0 0 0-1.851-.769zm0 0M16.365 0a3.784 3.784 0 0 0-2.783 1.145 3.792 3.792 
             0 0 0-1.146 2.784 3.772 3.772 0 0 0 1.145 2.78 3.951 3.951 0 0 0 5.564 0 3.79 3.79 0 0 0
              1.145-2.78 3.784 3.784 0 0 0-1.145-2.783A3.8 3.8 0 0 0 16.365 0zm0 0"
    />
  </svg>
);
